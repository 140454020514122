<template>

  <div class="self-center">

    <b-badge
      v-if="params.viewed"
      pill
      class="h-6"
      variant="success"
    >
      Viewed
    </b-badge>
    <b-badge
      v-else
      pill
      class="h-6"
      variant="danger"
    >
      Not Viewed
    </b-badge>
  </div>
</template>

<script>
export default {
  name: 'CellRendererViewed',
  components: {
  },
  props: {
    params: Object,
  },
  created() {
  },
}
</script>
