<template>
  <div class="self-center">
    <b-modal
      v-model="application_status_popup"
      centered
      hide-footer
      style=""
      title="Application Note"
    >
      <div class="vx-row mx-2">
        <div class="vx-row w-full mx-auto mb-1">
          <h6 class="mb-2 mt-1">
            Note
          </h6>
          <b-form-textarea
            v-model="application_note"
            rows="4"
            class="w-full"
          />
        </div>
      </div>
      <div class="vx-row w-3/4 mx-auto mb-1 ml-1">
        <b-button
          icon-pack="feather"
          icon="icon-edit-2"
          variant="warning"
          class="shadow-lg w-full ml-2"
          @click="updateApplicationStatus"
        >Update Application Note</b-button>
      </div>
    </b-modal>

    <p
      v-if="is_hiring_agency"
      class="whitespace-pre w-full h-auto"
    >
      {{ checkStatus(params.status) }}
    </p>
    <div
      v-else
      class="w-full ml-2"
    >

      <b-button
        v-if="params.status.note"
        ref="application_status"
        v-b-tooltip.hover.top="'Click on this button to change the application note'"
        class="m1-1 mb-1 shadow-lg"
        variant="warning"
        @click="viewApplicationStatus"
      >
        {{ params.status.note }}
      </b-button>
      <b-button
        v-else
        ref="application_status"
        class="mt-1 mb-1 shadow-lg"
        variant="warning"
        @click="viewApplicationStatus"
      >Update Application Note</b-button>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BFormInput,
  BFormRadio,
  BButton,
  BRow,
  BModal,
  VBTooltip,
  BAlert,
  BFormTextarea,
  BDropdown, BDropdownItem, BDropdownDivider,
} from 'bootstrap-vue'

export default {
  name: 'CellRendererApplicationNote',
  props: {
    params: Object,
  },
  components: {
    BCard,
    BFormRadio,
    BFormInput,
    BButton,
    BRow,
    BModal,
    BAlert,
    BFormTextarea,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      is_hiring_agency: false,
      application_status_popup: false,
      selected_application_status: '',
      application_note: '',
      booking_id: '',
      application_statuses: [
        { text: 'Hired', value: 'Hired' },
        { text: 'Shortlisted', value: 'Shortlisted' },
        { text: 'Kept In View', value: 'Kept In View' },
        { text: 'Rejected', value: 'Rejected' },
      ],
    }
  },
  created() {
    const { account_type } = this.$store.state.auth.ActiveUser
    if (account_type === this.$AccountTypes.AGENCY) {
      this.is_hiring_agency = true
    }
  },
  methods: {
    checkStatus(status) {
      const { note } = status

      if (note.length > 0) {
        return note
      }
      return 'Pending Review'
    },

    viewApplicationStatus() {
      this.application_status_popup = true
      this.selected_application_status = this.params.status.application_status
      this.application_note = this.params.status.note
      this.booking_id = this.params.booking_id
    },

    updateApplicationStatus() {
      const all_data = {
        booking_id: this.booking_id,
        status: {
          application_status: this.selected_application_status,
          note: this.application_note,
        },
      }
      this.updateBooking(all_data, 'status')
    },

    updateBooking(all_data, data_type) {
      this.$http
        .put('/api/book', { data: all_data })
        .then(response => {
          const { success } = response.data
          if (success) {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: data_type === 'status' ? 'Application Status' : 'Interview Scheduling',
                  icon: 'AlertCircleIcon',
                  text: data_type === 'status' ? 'Application status updated successfully.' : 'Interview scheduled successfully and applicant has been notified.',
                  variant: 'success',
                },
              },
              { timeout: this.$longestTimeout },
            )
            this.application_status_popup = false
            window.location.reload()
          } else {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: data_type === 'status' ? 'Application Status' : 'Interview Scheduling',
                  icon: 'AlertCircleIcon',
                  text: data_type === 'status' ? 'Application status update failed.' : 'Interview scheduling failed.',
                  variant: 'danger',
                },
              },
              { timeout: this.$longestTimeout },
            )
          }
        })
        .catch(error => {})
    },
  },
}
</script>
