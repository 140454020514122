<template>
  <div class="self-center">
    <b-modal
      v-model="application_status_popup"
      centered
      hide-footer
      style=""
      title="Application status"
    >

      <b-row
        cols="12"
        class="mx-1 mt-1 w-75"
      >
        <h6>
          Application Status
        </h6>
      </b-row>

      <b-row
        class="w-75 mx-1"
        cols="12"
      >
        <v-select
          v-model="selected_application_status"
          placeholder="Choose the application status"
          class="w-100"
          :options="application_statuses"
          label="value"
          :reduce="value => value.value"
        />
      </b-row>

      <b-row class="mx-1 my-1">
        <b-button
          icon-pack="feather"
          icon="icon-edit-2"
          variant="warning"
          class="shadow-lg w-full"
          @click="updateApplicationStatus"
        >Update Application Status</b-button>
      </b-row>
    </b-modal>

    <p
      v-if="is_hiring_agency"
      class="whitespace-pre w-full h-auto"
    >
      {{ checkStatus(params.status) }}
    </p>
    <div
      v-else
      class="w-full mb-1"
    >
      <b-button
        v-if="params.status.application_status"
        ref="application_status"
        v-b-tooltip.hover.top="'Click on this button to change the application status.'"
        class="shadow-lg"
        variant="warning"
        @click="viewApplicationStatus"
      >
        {{ params.status.application_status }}
      </b-button>
      <b-button
        v-else
        ref="application_status"
        class="mt-1 shadow-lg"
        variant="warning"
        @click="viewApplicationStatus"
      >Update Application Status</b-button>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BRow,
  BModal,
  VBTooltip,
} from 'bootstrap-vue'

import VSelect from 'vue-select'

export default {
  name: 'CellRendererApplicationStatus',
  props: {
    params: Object,
  },
  components: {
    BButton,
    BRow,
    BModal,
    VSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      is_hiring_agency: false,
      application_status_popup: false,
      selected_application_status: '',
      application_note: '',
      booking_id: '',
      application_statuses: [
        { text: 'Hired', value: 'Hired' },
        { text: 'Shortlisted', value: 'Shortlisted' },
        { text: 'Kept In View', value: 'Kept In View' },
        { text: 'Rejected', value: 'Rejected' },
      ],
    }
  },
  created() {
    const { account_type } = this.$store.state.auth.ActiveUser
    if (account_type === this.$UserGroups.AGENCY) {
      this.is_hiring_agency = true
    }
  },
  methods: {
    checkStatus(status) {
      const { application_status } = status

      if (application_status.length > 0) {
        return application_status
      }
      return 'Pending Review'
    },

    viewApplicationStatus() {
      this.application_status_popup = true
      this.selected_application_status = this.params.status.application_status
      this.application_note = this.params.status.note
      this.booking_id = this.params.booking_id
    },

    updateApplicationStatus() {
      const all_data = {
        booking_id: this.booking_id,
        status: {
          application_status: this.selected_application_status,
          note: this.application_note,
        },
      }
      this.updateBooking(all_data, 'status')
    },

    updateBooking(all_data, data_type) {
      this.$http
        .put('/api/book', { data: all_data })
        .then(response => {
          const { success } = response.data
          if (success) {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: data_type === 'status' ? 'Application Note' : 'Interview Scheduling',
                  icon: 'AlertCircleIcon',
                  text: data_type === 'status' ? 'Application note updated successfully.' : 'Interview scheduled successfully and applicant has been notified.',
                  variant: 'success',
                },
              },
              { timeout: this.$longestTimeout },
            )
            this.application_status_popup = false
            window.location.reload()
          } else {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: data_type === 'status' ? 'Application Note' : 'Interview Scheduling',
                  icon: 'AlertCircleIcon',
                  text: data_type === 'status' ? 'Application note update failed.' : 'Interview scheduling failed.',
                  variant: 'danger',
                },
              },
              { timeout: this.$longestTimeout },
            )
          }
        })
        .catch(error => {})
    },
  },
}
</script>
