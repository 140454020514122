<template>
  <div class="vx-row sm:w-full">
    <div class="vx-row ml-4">
      <b-button
        v-if="!is_popup"
        variant="warning"
        type="filled"
        @click="getBack()"
      >Go Back</b-button>
    </div>

    <b-card class="flex flex-row justify-center w-full mx-4 my-4 p-4">
      <div class="container w-full">
        <div class="flex ml-1">
          <h6 class="font-semibold">
            1. How long will each interview last?
          </h6>
        </div>

        <div class="flex ml-1 mt-4">
          <b-form-input
            v-model="duration"
            class="my-auto w-16 mx-4"
          />
          <p class="my-auto text-lg font-semibold text-center">
            Minutes
          </p>
        </div>
      </div>

      <div class="container mt-6">
        <div class="flex ml-1">
          <h6 class="font-semibold">
            2. Specify your schedule below
          </h6>
        </div>

        <b-card
          class="flex w-full mt-4 ml-1 p-6"
          no-shadow
          card-border
        >
          <h6 class="font-semibold">
            Set your weekly hours
          </h6>

          <div
            v-for="(value, name, index) in weekly_hours"
            :key="index"
            class="vx-row w-full my-4 mx-4"
          >
            <div class="vx-row w-full mb-8 mt-4">
              <div class="vx-col w-1/5 my-auto">
                <b-form-checkbox
                  v-model="weekly_hours[name].available"
                  class="font-semibold mx-4"
                >{{ name }}</b-form-checkbox>
              </div>
              <div
                v-if="weekly_hours[name].available"
                class="vx-col w-2/5 my-auto"
              >
                <div
                  v-for="(sel_time, index) in weekly_hours[name].selected_time"
                  :key="index"
                  class="flex my-4"
                >
                  <v-select
                    v-model="weekly_hours[name].selected_time[index].start_time"
                    class="w-full"
                    :options="time_ranges"
                    label="value"
                  />

                  <hr
                    variant="dark"
                    class="w-6 font-semibold mx-2"
                  >
                  <v-select
                    v-model="weekly_hours[name].selected_time[index].end_time"
                    class="w-full"
                    :options="time_ranges"
                    label="value"
                  />
                </div>
              </div>
              <div
                v-if="weekly_hours[name].available"
                class="vx-col w-1/3 my-auto"
              >
                <b-button
                  radius
                  variant="warning"
                  type="border"
                  icon-pack="feather"
                  icon="icon-plus"
                  class="mb-1"
                  @click="addTimeSlot(name)"
                />
              </div>

              <div
                v-else
                class="vx-col my-auto"
              >
                <h6 class="font-semibold text-danger">
                  Hidden Info
                </h6>
              </div>
            </div>

            <hr>
          </div>
        </b-card>
      </div>

      <div class="container w-full mt-6">
        <div class="vx-row ml-1">
          <h6 class="font-semibold">
            3. Want to add time before or after your interviews?
          </h6>
        </div>

        <div class="vx-row ml-1">
          <p class="italic mt-2">
            Give yourself some buffer time to prepare for or wrap up from booked
            interviews.
          </p>
        </div>

        <div class="vx-row ml-1 mt-6">
          <b-form-checkbox
            v-model="buffer_time.before.active"
            class="mx-4 font-semibold"
          >Before Interview</b-form-checkbox>
        </div>
        <div class="vx-row ml-1 my-4">
          <b-form-input
            v-model="buffer_time.before.duration"
            :disabled="!buffer_time.before.active"
            class="y-auto w-16 mx-4 font-semibold"
          />
          <p class="my-auto text-lg font-semibold text-center">
            Minutes
          </p>
        </div>

        <div class="vx-row ml-1 mt-6">
          <b-form-checkbox
            v-model="buffer_time.after.active"
            class="mx-4 font-semibold"
          >After Interview</b-form-checkbox>
        </div>
        <div class="vx-row ml-1 my-4">
          <b-form-input
            v-model="buffer_time.after.duration"
            :disabled="!buffer_time.after.active"
            class="my-auto w-16 mx-4 font-semibold"
          />
          <p class="my-auto text-lg font-semibold text-center">
            Minutes
          </p>
        </div>

        <div class="vx-row mt-8 ml-1">
          <b-button @click="saveChanges">
            Save Changes
          </b-button>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>

import {
  isBefore,
  setHours,
  setMinutes,
  setSeconds,
  addMinutes,
  setMilliseconds,
  format,
} from 'date-fns'

export default {
  components: {},
  props: {
    is_popup: { type: Boolean, default: false },
  },
  data() {
    return {
      saved_duration: null,
      duration: 10,
      time_ranges: [],
      weekly_hours: {
        SUN: {
          available: false,
          selected_time: [],
        },
        MON: {
          available: true,
          selected_time: [],
        },
        TUE: {
          available: true,
          selected_time: [],
        },
        WED: {
          available: true,
          selected_time: [],
        },
        THUR: {
          available: true,
          selected_time: [],
        },
        FRI: {
          available: true,
          selected_time: [],
        },
        SAT: {
          available: false,
          selected_time: [],
        },
      },
      buffer_time: {
        before: {
          active: false,
          duration: 10,
        },
        after: {
          active: false,
          duration: 10,
        },
      },
      searchTimer: null,
    }
  },
  watch: {
    duration(val) {
      clearTimeout(this.searchTimer)
      this.searchTimer = setTimeout(() => {
        if (val !== this.saved_duration) {
          this.generateSlots()
        } else {
          this.getBookingSlots()
          this.generateSlots()
        }
      }, 1000)
    },
  },
  created() {
    const { token } = this.$store.state.auth.ActiveUser
    this.$http.defaults.headers.common.Authorization = `Token ${token}`

    this.generateSlots()
    this.getBookingSlots()
  },
  methods: {
    saveChanges() {
      const all_data = {
        weekly_hours: this.weekly_hours,
        buffer_time: this.buffer_time,
        duration: this.duration,
      }
      this.$http
        .post('/api/booking-slots', { data: all_data })
        .then(response => {
          const { success } = response.data
          const { message } = response.data
          if (success) {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Timeslots Update',
                  icon: 'AlertCircleIcon',
                  text: message,
                  variant: 'success',
                },
              },
              { timeout: this.$longestTimeout },
            )
          } else {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Timeslots Update',
                  icon: 'AlertCircleIcon',
                  text: message,
                  variant: 'danger',
                },
              },
              { timeout: this.$longestTimeout },
            )
          }
        })
        .catch(error => {})
    },
    getBookingSlots() {
      this.$http
        .get('/api/booking-slots')
        .then(response => {
          const { success } = response.data
          const { output } = response.data

          if (success) {
            this.weekly_hours = output.weekly_hours
            this.buffer_time = output.buffer_time
            this.duration = output.duration
            this.saved_duration = output.duration
          }
        })
        .catch(error => {})
    },
    addTimeSlot(name) {
      this.weekly_hours[name].selected_time.push({})
    },
    setDefaultValue() {
      this.weekly_hours = {
        SUN: {
          available: false,
          selected_time: [],
        },
        MON: {
          available: true,
          selected_time: [],
        },
        TUE: {
          available: true,
          selected_time: [],
        },
        WED: {
          available: true,
          selected_time: [],
        },
        THUR: {
          available: true,
          selected_time: [],
        },
        FRI: {
          available: true,
          selected_time: [],
        },
        SAT: {
          available: false,
          selected_time: [],
        },
      }
    },
    generateSlots() {
      const setTime = (x, h = 0, m = 0, s = 0, ms = 0) => setHours(setMinutes(setSeconds(setMilliseconds(x, ms), s), m), h)

      const from = setTime(new Date(), 0)
      const to = setTime(new Date(), 24)

      let additional_duration = 0
      if (this.buffer_time.before.active) {
        additional_duration += Number(this.buffer_time.before.duration)
      }
      if (this.buffer_time.after.active) {
        additional_duration += Number(this.after.before.duration)
      }
      const final_duration = Number(this.duration) + additional_duration

      const step = x => addMinutes(x, final_duration)

      const blocks = []
      let cursor = from

      while (isBefore(cursor, to)) {
        const formatted_time = format(cursor, 'HH:mm')
        blocks.push(formatted_time)
        cursor = step(cursor)
      }
      this.time_ranges = blocks
      // this.setDefaultValue();
      for (const item in this.weekly_hours) {
        const selected_times = this.weekly_hours[item].selected_time

        this.weekly_hours[item].selected_time.push({
          start_time: blocks[0],
          end_time: blocks[blocks.length - 1],
        })
      }
    },
  },
}
</script>

<style>
.divider {
  width: 2px;
  background-color: rgba(94, 91, 91, 0.11);
  margin-left: 0px;
  margin-right: 0px;
  height: 10cm;
}
.show-border * {
  border: 1px solid #000000;
}
</style>
